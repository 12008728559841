import React from 'react';
import styled from '@emotion/styled';

const StyleProductPrice = styled.div`
	.product__pricingv3 {
		&__unlimited {
			img {
				height: 16px;
			}
		}
		&__bottles {
			color: #9e9e9e;
			font-size: 14px;
		}
		&__vpp {
			h6 {
				font-size: 18px;
			}
			span {
				font-size: 12px;
			}
		}
	}
	.plp_price_section {
		height: 90px;
	}
`;

const ProductPrice = ({ product }) => {
	const skus = product?.skus && product.skus[0];
	const isMemberPriceAvailable = (skus?.memberPrice ?? 0) > 0;
	const isMixed = product?.mixed;

	return (
		<StyleProductPrice>
			<div
				className={`mb-8 d-flex justify-content-evenly flex-column plp_price_section`}
			>
				{/** Top Price */}
				{!isMixed ? (
					<div className='product__pricingv3__rrp d-flex align-items-center'>
						{skus?.listPrice === skus?.salePrice ? (
							<h5 className='roboto pr-12 mb-0'>
								${parseFloat(skus?.salePrice).toFixed(2)}
							</h5>
						) : null}
						<span className='text'>RRP</span>
					</div>
				) : (
					<div className='product__pricingv3__prices-mixed d-flex align-items-center'>
						{skus.listPrice - skus.salePrice > 0 ? (
							<>
								<div className='product__pricingv3__rrp d-flex text-decoration-line-through'>
									{(isMixed || product.productType === 'nonfoodsundry') && (
										<span
											className={`pr-8 ${
												product.productType === 'nonfoodsundry'
													? 'text-decoration-line-through'
													: ''
											}`}
										>
											${parseFloat(skus.listPrice).toFixed(2)}
										</span>
									)}
								</div>
								<div className='product__pricingv3__vpp d-flex mixed'>
									<h6 className='roboto mb-0 text-dark-medium'>
										${parseFloat(skus.salePrice).toFixed(2)}
									</h6>
								</div>
								<div className='product__pricingv3__save font-weight-bold text-green pl-8'>
									(Save $
									{parseFloat(skus.listPrice - skus.salePrice).toFixed(2)})
								</div>
							</>
						) : (
							<div className='product__pricingv3__vpp d-flex mixed'>
								<h5 className='roboto pr-12 mb-0'>
									${parseFloat(skus.salePrice).toFixed(2)}
								</h5>
							</div>
						)}
					</div>
				)}
				{/** End Top Price */}
				{isMixed ? (
					<div className='d-flex mb-6'>
						<span className='text-dark-medium'>
							<span className='product__pricingv3__bottle-count'>
								{`${skus.numberOfBottles} bottles`}
							</span>
							<span className='pl-4 pr-4'>-</span>
						</span>
						<span>(${parseFloat(skus.salePricePerBottle).toFixed(2)} ea)</span>
					</div>
				) : null}
				{/** VPP */}
				{skus?.vppApplier && !isMixed ? (
					<div className='product__pricingv3__vpp d-flex mb-6 align-items-center'>
						{!isMixed && (skus?.salePrice > 0 || skus?.listPrice > 0) ? (
							<h6 className='roboto lower pr-8 mb-0 text-dark-medium'>Only</h6>
						) : null}
						<h6 className='roboto mb-0 text-dark-medium'>
							${parseFloat(skus?.vppPrice).toFixed(2)}
						</h6>
						{!isMixed ? <span className='ml-12'>mix 12 or more</span> : null}
					</div>
				) : null}
				{/** End VPP */}
				{/** Member Price (unlimited) */}
				{isMemberPriceAvailable ? (
					<div className='product__pricingv3__unlimited'>
						<div className='d-flex align-items-center justify-content-start'>
							<img
								src='/images/au/common/logos/unlimited_logo.svg'
								alt='Unlimited'
								className='img-responsive mr-8 h-16'
							/>
							<h6 className='text-unlimited lower roboto mr-8 mb-0'>
								${parseFloat(skus?.memberPrice).toFixed(2)}
							</h6>
							{!isMixed ? (
								<span className='product__pricingv3__unlimited__bottles'>
									mix 12 or more
								</span>
							) : (
								<span className='product__pricingv3__unlimited__bottles'>
									($
									{parseFloat(skus.memberPrice / skus.numberOfBottles).toFixed(
										2
									)}{' '}
									ea)
								</span>
							)}
						</div>
					</div>
				) : null}
				{/** End Member Price (unlimited) */}
			</div>
		</StyleProductPrice>
	);
};

export default ProductPrice;
