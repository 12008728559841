import React, { useState } from 'react';
import { AddtoCart } from 'dw-au-react-components';

const AddtoCartProduct = ({ itemCode }) => {
	const [addingToCart, setAddingToCart] = useState(null);
	const [addingtoCartError, setAddingtoCartError] = useState(null);
	const [addingtoCartErrorItemCode, setAddingtoCartErrorItemCode] = useState(
		null
	);

	const handleAddtoCart = async (itemCode) => {
		if (!addingToCart) {
			setAddingToCart(itemCode);
			setAddingtoCartError(null);
			const isNewCartAvailable = miniCart.isNewCartAvailable();

			if (isNewCartAvailable) {
				const event = await miniCart.newAddToCart(itemCode, 1);
				console.log('account event', event);
				if (event.detail.error === false) {
					setAddingToCart(null);
				} else {
					setAddingToCart(null);
					setAddingtoCartErrorItemCode(itemCode);
					setAddingtoCartError(
						event?.detail?.message?.response?.data?.errorResponse?.message ||
							event?.detail?.message?.response?.data?.message ||
							event?.detail?.message?.response?.data?.statusMessage ||
							'Something went wrong, please try again later.'
					);

					setTimeout(() => {
						setAddingtoCartError(null);
						setAddingtoCartErrorItemCode(null);
					}, 3000);
				}
				return;
			}

			AddtoCart(itemCode)
				.then((response) => {
					if (response?.status === 200 && window?.miniCart) {
						setAddingToCart(null);

						/* eslint-disable */
						miniCart.updateEmbeddedCart('added', itemCode, 1);
						miniCart.config.data = response?.data;
						miniCart.updateMiniCart();
						miniCart.toggleMiniCart();
						/* eslint-enable */
					}
				})
				.catch((error) => {
					setAddingToCart(null);
					setAddingtoCartErrorItemCode(itemCode);
					setAddingtoCartError(
						error?.response?.data?.errorResponse?.message ||
							error?.response?.data?.statusMessage ||
							'Something went wrong, please try again later.'
					);

					setTimeout(() => {
						setAddingtoCartError(null);
						setAddingtoCartErrorItemCode(null);
					}, 3000);
				});
		}
	};

	return (
		<div>
			<button
				title={'Add to Cart'}
				className={`btn btn-primary h44 w-190 text-uppercase`}
				onClick={() => handleAddtoCart(itemCode)}
			>
				{addingToCart === itemCode ? 'Adding...' : 'Add to Cart'}
			</button>
			{addingtoCartErrorItemCode === itemCode && addingtoCartError && (
				<div className='mt-20 pr-20 text-error'>{addingtoCartError}</div>
			)}
		</div>
	);
};

export default AddtoCartProduct;
